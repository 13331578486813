<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">会员卡管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="服务套餐">
					<a-select placeholder="请选择服务套餐" v-model="query.package_id">
						<a-select-option :value="item.package_id" v-for="item in levelList" :key="item.package_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="卡号">
					<a-input v-model="query.card_no" placeholder="请输入卡号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="状态">
					<a-select placeholder="请选择" v-model="query.status">
						<a-select-option :value="0" :key="0">
							全部
						</a-select-option>
						<a-select-option :value="1" :key="1">
							已发放
						</a-select-option>
						<a-select-option :value="2" :key="2">
							未发放
						</a-select-option>
						<a-select-option :value="3" :key="3">
							已过期
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增卡片</a-button>
			<a-button type="cancel" @click="downTem" style="margin-left: 10px">下载导入模板</a-button>
			<a-upload style="margin-left: 10px" accept=".xlsx,.xls,.cvn" :multiple="false" :beforeUpload="beforeUpload"
				:showUploadList="false" :headers="{
					Authorization: 'Bearer ' + token
				}" @change="handleChange" :action="$domain + '/admin/v1/card/ImportExcel'">
				<a-button> <a-icon type="upload" /> 导入表格 </a-button>
			</a-upload>
			<a-button type="primary" @click="toExportMember" style="float: right; margin-right: 0">导出会员卡</a-button>
			<a-tooltip placement="topLeft" style="float: right;" arrow-point-at-center>
				<template slot="title">
					<span>仅支持导出状态是未发放的卡片，可选择导出数量</span>
				</template>
				<div style="text-align: center;margin-right: 10px;margin-top: 5px;">
					<img src="@/static/icon/warn.png" style="width: 18px; height: auto;">
				</div>
			</a-tooltip>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="status" slot-scope="text, record">
				<div v-if="record.status == 1">已发放</div>
				<div v-if="record.status != 1 && record.activation_end_time < currentTimeInSeconds">已过期</div>
				<div v-if="record.status != 1 && record.activation_end_time > currentTimeInSeconds">未发放</div>
			</span>
			<span slot="useTime" slot-scope="text, record">
				{{ record.begin_time_str }}至{{ record.end_time_str }}
			</span>
			<span slot="acTime" slot-scope="text, record">
				{{ record.activation_begin_time_str }}至{{ record.activation_end_time_str }}
			</span>
			<span slot="action" slot-scope="text, record">
				<div v-if="record.status == 1"><a @click="info(record)">详情</a></div>
				<div v-if="record.status != 1 && record.activation_end_time < currentTimeInSeconds">
					<a-space>
						<a @click="info(record)">详情</a>
						<a @click="toEdit(record)">激活</a>
					</a-space>
				</div>
				<div v-if="record.status != 1 && record.activation_end_time > currentTimeInSeconds">
					<a-space>
						<a @click="toEdit(record)">编辑</a>
						<a @click="toGive(record)">发放</a>
					</a-space>
				</div>
			</span>
		</a-table>

		<!-- 抽屉1 添加/修改 -->
		<a-drawer :title="label + '卡片'" :width="720" :visible="visible1" :body-style="{ paddingBottom: '80px' }"
			@close="onClose1">

			<a-form-model ref="customerForm" :model="customerForm" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item ref="name" label="卡号" prop="name">
					<a-input v-model="customerForm.card_no" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="无需编辑，创建成功后自动生成" disabled />
				</a-form-model-item>
				<a-form-model-item label="套餐" prop="package_id">
					<a-select v-model="customerForm.package_id" :disabled="infoDis || customer_card_id != null"
						placeholder="请选择">
						<a-select-option :value="item.package_id" v-for="item in levelList" :key="item.package_id"
							placeholder="请选择">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="useTime" label="使用有效期" prop="useTime">
					<a-range-picker @change="onChange1" style="width: 390px;" :disabled="infoDis" valueFormat="YYYY-MM-DD"
						v-model="useTimeArr" />
					<row>
						<div style=" color: #E0352B;line-height:1.4">会员卡使用有效期：有效期内可预约服务；</div>
					</row>
				</a-form-model-item>
				<a-form-model-item label="激活效期" prop="acTime" ref="acTime" style="padding-bottom: 5px;">
					<a-range-picker @change="onChange2" style="width: 390px;" :disabled="infoDis" valueFormat="YYYY-MM-DD"
						v-model="acTimeArr" />
					<row>
						<div style="color: #E0352B;width: 390px; line-height:1.4">
							实体卡有激活功能；在激活有效期内卡片才能被激活；激活效期外或会员卡使用有效期外则不支持激活；
						</div>
					</row>
				</a-form-model-item>
			</a-form-model>

			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose1">
					取消
				</a-button>
				<a-button type="primary" @click="submitForm">
					确定
				</a-button>
			</div>
		</a-drawer>


		<!-- 抽屉2 服务类型 -->
		<a-drawer title="发放" :width="750" :visible="visible2" :body-style="{ paddingBottom: '80px' }" @close="onClose2">
			<a-form-model ref="giveForm" :model="giveForm" :rules="rules3" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="请选择发放用户" prop="customer_id" ref="customer_id">
					<a-select show-search :value="giveForm.customer_id" placeholder="可输入手机号查找" style="width: 390px"
						:default-active-first-option="false" :show-arrow="false" :filter-option="false"
						:not-found-content="null" @search="handleSearchCustomer" @change="handleChangeCustomer"
						@blur="blur">
						<a-select-option v-for="d in customerList" :key="d.customer_id">
							{{ d.phone }}({{ d.name }})
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-form-model>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose2">
					取消
				</a-button>
				<a-button type="primary" @click="giveCard()">
					确定
				</a-button>
			</div>
		</a-drawer>

		<!-- 抽屉3 服务类型详情 -->
		<a-drawer :title="'导出数量'" :width="650" :visible="visible3" :body-style="{ paddingBottom: '80px' }"
			@close="onClose3">

			<a-form-model ref="classForm" :model="classForm" :rules="rules2" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item ref="number" label="导出数量" prop="number">
					<a-input-number v-model="classForm.number" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
			</a-form-model>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose3">
					取消
				</a-button>
				<a-button type="primary" @click="submitClass">
					确定
				</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择套餐')); // 验证失败，返回错误信息
			}
		};
		let validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入金额'));
			} else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			query: {
				page: 1,
				limit: 10
			},
			customerList: [],
			classQuery: {
				page: 1,
				limit: 10
			},
			exNumber: null,
			currentTimeInSeconds: 0,
			customer_id: null,
			pagetotal: 0,
			infoDis: false,
			classPagetotal: 0,
			classList: [],
			checkValue: [],
			packageList: [],
			tags: ['Tag1', 'Tag2', 'Tag3'],
			inputValue: '',
			giveForm: {
				"customer_id": null
			},
			checkOptions: [
				{
					label: '使用中',
					value: 1
				},
				{
					label: '已完成',
					value: 2
				},
				{
					label: '已退款',
					value: 3
				}
			],
			useTimeArr: [],
			acTimeArr: [],
			visible1: false,
			visible2: false,
			visible3: false,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			other: '',
			label: '',
			customerForm: {
				package_id: '',
			},
			customer_card_id: null,
			classForm: { number: null },
			class_id: null,
			rules: {
				package_id: [
					{ required: true, validator: validateClass, trigger: 'blur' },
				],
				// useTime: [{ required: true, message: "请选择类型", trigger: 'change' }],
				// acTime: [
				// 	{ required: true, message: '请输入服务介绍', trigger: 'blur' },
				// ],
			},
			rules2: {
				number: [
					{ required: true, message: '请输入数量', trigger: 'blur' },
				],
			},
			rules3: {
				customer_id: [
					{ required: true, validator: validateClass, message: '请选择发放用户', trigger: 'blur' },
				],
			},
			columns: [
				{
					title: '卡号',
					dataIndex: 'card_no',
				},
				{
					title: '服务套餐',
					dataIndex: 'name',
				},
				{
					title: '套餐售价',
					dataIndex: 'sale_money',
				},
				{
					title: '状态',
					scopedSlots: {
						customRender: 'status'
					}
				},
				{
					title: '使用有效期',
					scopedSlots: {
						customRender: 'useTime'
					}
				},
				{
					title: '激活有效期',
					scopedSlots: {
						customRender: 'acTime'
					}
				},
				{
					title: '创建时间',
					dataIndex: 'create_at_str',
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name',
					customRender: (text, record, index) => {
						return {
							children: text,
							attrs: {
								style: 'width: 60%;', // 指定列宽度为 30%
							},
						};
					},
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	computed: {
		formattedDate() {
			return function (time) {
				const date = new Date(time * 1000);  // 将秒级时间戳转换为毫秒级时间戳
				return date.toISOString().slice(0, 10);  // 获取日期部分，例如 "2023-01-01"
			};
		},
		token() {
			return this.$store.state.token
		}
	},
	created() {
		this.updateCurrentTimeInSeconds()
		this.getList()
		this.getLevelList()
	},
	methods: {
		handleSearchCustomer(value) {
			this.getCustomerList(value)
		},
		handleChangeCustomer(value) {
			console.log("change", value);
			this.giveForm.customer_id = value;
		},
		blur() {
			console.log("失去焦点")
			this.getCustomerList('')
		},
		beforeUpload(file) {
			this.file = file
			this.uping = true
			this.spinning = true
			console.log('token', this.token)
			console.log('before', file)
		},
		getCustomerList(phone) {
			this.$post(domain + '/admin/v1/customer/listNoPage', { "phone": phone }).then(res => {
				if (res.code === 0) {
					this.customerList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleChange(e) {
			console.log('token', this.token)
			console.log('改变。。。')
			if (e.file.status == 'done') {
				console.log(e.file.response)
				this.spinning = false
				this.uping = false
				let { msg, data, code } = e.file.response
				if (code == 0) {
					this.$message.success(msg, 1.5)
					this.getList()
				} else {
					this.$message.warn(msg, 1.5)
				}
				console.log(data)
			}
		},
		onChange1(a, b) {
			this.customerForm.begin_time_str = b[0]
			this.customerForm.end_time_str = b[1]
		},
		onChange2(a, b) {
			this.customerForm.activation_begin_time_str = b[0]
			this.customerForm.activation_end_time_str = b[1]
		},
		toExportMember() {
			this.visible3 = true
		},
		downTem() {
			window.location.href = domain + '/admin/v1/card/downTemplate'
		},
		updateCurrentTimeInSeconds() {
			this.currentTimeInSeconds = Math.floor(Date.now() / 1000);
		},
		toWenjuan(record) {
			this.$post(domain + '/admin/v1/customer/memberPackage', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					this.packageList = res.data
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		// toClass() {
		// 	this.getClassList()
		// 	this.visible2 = true
		// },
		info(record) {
			this.label = ""
			this.$post(domain + '/admin/v1/card/info', { customer_card_id: record.customer_card_id }).then(res => {
				if (res.code === 0) {
					this.customerForm = res.data
					this.useTimeArr = [this.customerForm.begin_time_str, this.customerForm.end_time_str]
					this.acTimeArr = [this.customerForm.activation_begin_time_str, this.customerForm.activation_end_time_str]
					this.visible1 = true
					this.infoDis = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toEdit(record) {
			this.label = '编辑'
			this.customer_card_id = record.customer_card_id
			this.$post(domain + '/admin/v1/card/info', { customer_card_id: record.customer_card_id }).then(res => {
				if (res.code === 0) {
					this.customerForm = res.data
					this.useTimeArr = [this.customerForm.begin_time_str, this.customerForm.end_time_str]
					this.acTimeArr = [this.customerForm.activation_begin_time_str, this.customerForm.activation_end_time_str]
					this.visible1 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		delClass(class_id) {
			this.$post(domain + '/admin/v1/serviceClass/delete', { "class_id": class_id }).then(res => {
				if (res.code === 0) {
					this.$message.success("删除成功")
					this.getClassList()
					this.getLevelList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		submitForm() {
			this.$refs.customerForm.validate((valid) => {
				if (valid) {
					let action = "";
					if (this.customer_card_id != null) {
						action = "update"
						this.customerForm.customer_card_id = this.customer_card_id
					} else {
						action = "add"
					}
					this.$post(domain + '/admin/v1/card/' + action, this.customerForm).then(res => {
						if (res.code === 0) {
							this.$message.success(res.msg)
							this.visible1 = false
							this.infoDis = false
							this.initForm()
							this.getList()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			});
		},
		submitClass() {
			this.$refs.classForm.validate((valid) => {
				if (valid) {
					if (this.classForm.number <= 0) {
						this.$message.error("请输入正确的数量")
						return;
					}
					window.location.href = domain + '/admin/v1/card/downExcel?number=' + this.classForm.number
					this.visible3 = false
					this.initClassForm()
				}
			});
		},

		addClass() {
			this.visible3 = true
		},
		// getClassList() {
		// 	this.$post(domain + '/admin/v1/serviceClass/list', this.classQuery).then(res => {
		// 		if (res.code === 0) {
		// 			this.classPagetotal = res.data.total
		// 			this.classList = res.data.list
		// 			this.visible2 = true
		// 		} else {
		// 			this.$message.error(res.msg)
		// 		}
		// 	})
		// },
		toPackage(record) {
			this.$post(domain + '/admin/v1/customer/memberPackage', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					this.packageList = res.data
					this.visible3 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		onClose3() {
			this.visible3 = false
			this.initClassForm()
		},
		onClose2() {
			this.visible2 = false
			this.giveForm.customer_id = null
			this.customer_card_id = null
		},
		toGive(record) {
			this.customer_card_id = record.customer_card_id
			this.getCustomerList('')
			this.visible2 = true
		},
		giveCard() {
			this.$refs.giveForm.validate((valid) => {
				if (valid) {
					this.$post(domain + '/admin/v1/card/give', { customer_id: this.giveForm.customer_id, customer_card_id: this.customer_card_id }).then(res => {
						if (res.code === 0) {
							this.$message.success("发放成功")
							this.visible2 = false
							this.getList()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			});
		},
		initClassForm() {
			this.class_id = null
			this.classForm = {
				number: null
			}
		},
		handleClose(removedTag) {
			const tags = this.customerForm.tag.filter(tag => tag !== removedTag);
			this.customerForm.tag = tags;
		},
		handleInputConfirm() {
			const inputValue = this.inputValue;
			let tags = JSON.parse(JSON.stringify(this.customerForm.tag));
			if (inputValue && tags.indexOf(inputValue) === -1) {
				tags = [...tags, inputValue];
			}
			this.customerForm.tag = tags;
			console.log("customerForm", this.customerForm.tag)
			this.inputValue = '';
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		popupScroll() {
			console.log('popupScroll');
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		add() {
			this.label = "新增"
			console.log("customer_id", this.customer_id)
			this.visible1 = true;
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
			this.infoDis = false
		},
		initForm() {
			this.customer_card_id = null
			this.customerForm = {
				package_id: ''
			}
			this.useTimeArr = []
			this.acTimeArr = []
		},
		toOrder(item) {
			if (item.rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						cid: item.customer_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/card/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList() {
			this.$post(domain + '/admin/v1/package/listNoPage').then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		classListChange(e) {
			this.classQuery.page = e.current
			this.getClassList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
